import React, { createContext, useContext, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Check, X, AlertTriangle, Info } from 'lucide-react';

export type NotificationType = 'success' | 'error' | 'warning' | 'info';

interface Notification {
  id: string;
  type: NotificationType;
  message: string;
  duration?: number;
}

interface NotificationContextType {
  showNotification: (type: NotificationType, message: string, duration?: number) => void;
  clearNotification: (id: string) => void;
  clearAllNotifications: () => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const generateId = () => Math.random().toString(36).substr(2, 9);

  const showNotification = (type: NotificationType, message: string, duration = 5000) => {
    const id = generateId();
    const notification = { id, type, message, duration };
    
    setNotifications(prev => [...prev, notification]);

    if (duration > 0) {
      setTimeout(() => {
        clearNotification(id);
      }, duration);
    }
  };

  const clearNotification = (id: string) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  const clearAllNotifications = () => {
    setNotifications([]);
  };

  const getNotificationStyle = (type: NotificationType) => {
    switch (type) {
      case 'success':
        return {
          background: 'bg-gradient-to-r from-green-500 to-emerald-600',
          icon: <Check className="w-5 h-5 text-white" />,
          iconBg: 'bg-green-400'
        };
      case 'error':
        return {
          background: 'bg-gradient-to-r from-red-500 to-rose-600',
          icon: <X className="w-5 h-5 text-white" />,
          iconBg: 'bg-red-400'
        };
      case 'warning':
        return {
          background: 'bg-gradient-to-r from-yellow-500 to-amber-600',
          icon: <AlertTriangle className="w-5 h-5 text-white" />,
          iconBg: 'bg-yellow-400'
        };
      case 'info':
        return {
          background: 'bg-gradient-to-r from-blue-500 to-cyan-600',
          icon: <Info className="w-5 h-5 text-white" />,
          iconBg: 'bg-blue-400'
        };
    }
  };

  return (
    <NotificationContext.Provider value={{ showNotification, clearNotification, clearAllNotifications }}>
      {children}
      <div className="fixed top-4 right-4 z-50 space-y-2 min-w-[320px]">
        <AnimatePresence>
          {notifications.map((notification) => {
            const style = getNotificationStyle(notification.type);
            return (
              <motion.div
                key={notification.id}
                initial={{ opacity: 0, y: -20, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: -20, scale: 0.95 }}
                className={`flex items-center gap-3 px-4 py-3 rounded-lg shadow-lg backdrop-blur-sm ${style.background}`}
              >
                <div className={`p-1.5 rounded-full ${style.iconBg}`}>
                  {style.icon}
                </div>
                <p className="text-sm font-medium text-white flex-1 mr-2">{notification.message}</p>
                <button
                  onClick={() => clearNotification(notification.id)}
                  className="p-1.5 hover:bg-white/20 rounded-full transition-colors"
                >
                  <X className="w-4 h-4 text-white" />
                </button>
              </motion.div>
            );
          })}
        </AnimatePresence>
      </div>
    </NotificationContext.Provider>
  );
}; 