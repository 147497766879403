import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { Logo } from '../ui/Logo';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../config/firebase';
import {
  LayoutDashboard,
  MessageSquare,
  Settings,
  LogOut,
  ChevronRight,
  Wallet,
  Menu,
  X,
  Shield,
} from 'lucide-react';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

interface MenuItemProps {
  item: {
    name: string;
    icon: React.ComponentType<{ className?: string }>;
    path: string;
    color: string;
    description: string;
  };
  index: number;
  isMobile?: boolean;
}

const getMenuItems = (isAdmin: boolean) => {
  const baseItems = [
    { 
      name: 'Личный кабинет',
      icon: LayoutDashboard,
      path: '/dashboard',
      color: 'from-blue-600 to-cyan-600',
      description: 'Управление ботами и основная информация'
    },
    { 
      name: 'История чатов',
      icon: MessageSquare,
      path: '/dashboard/chats',
      color: 'from-indigo-600 to-purple-600',
      description: 'История сообщений и диалогов'
    },
    { 
      name: 'Настройки профиля',
      icon: Settings,
      path: '/dashboard/settings',
      color: 'from-purple-600 to-pink-600',
      description: 'Персональные настройки аккаунта'
    },
  ];

  if (isAdmin) {
    baseItems.push({
      name: 'Админ панель',
      icon: Shield,
      path: '/dashboard/admin',
      color: 'from-red-600 to-orange-600',
      description: 'Управление пользователями и системой'
    });
  }

  return baseItems;
};

export default function DashboardLayout({ children }: DashboardLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, logout, isAdmin } = useAuth();
  const [balance, setBalance] = React.useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const menuItems = getMenuItems(isAdmin);

  React.useEffect(() => {
    if (currentUser) {
      const unsubscribe = onSnapshot(doc(db, 'users', currentUser.uid), (doc) => {
        if (doc.exists()) {
          setBalance(doc.data()?.balance || 0);
        }
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Ошибка при выходе:', error);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const MenuItem = ({ item, index, isMobile = false }: MenuItemProps) => {
    const isActive = location.pathname === item.path;
    return (
      <motion.div
        key={item.name}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: index * 0.1 }}
      >
        <button
          onClick={() => {
            navigate(item.path);
            if (isMobile) setIsMobileMenuOpen(false);
          }}
          className={`group relative flex items-center gap-3 w-full p-4 text-sm font-medium rounded-xl transition-all duration-200 ${
            isActive
              ? 'text-white shadow-lg bg-gradient-to-r ' + item.color
              : 'text-gray-700 hover:bg-white hover:shadow-md'
          }`}
        >
          <div className={`flex h-10 w-10 shrink-0 items-center justify-center rounded-lg ${
            isActive
              ? 'bg-white/20'
              : 'bg-gradient-to-r ' + item.color + ' text-white'
          }`}>
            <item.icon className="h-5 w-5" />
          </div>
          <div className="flex flex-col">
            <span className="font-semibold">{item.name}</span>
            <span className={`text-xs ${
              isActive ? 'text-white/80' : 'text-gray-500'
            }`}>
              {item.description}
            </span>
          </div>
          {isActive && (
            <ChevronRight className="h-5 w-5 absolute right-4 top-1/2 -translate-y-1/2" />
          )}
        </button>
      </motion.div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Header */}
      <header className="bg-white/80 backdrop-blur-md border-b border-gray-200 sticky top-0 z-20">
        <div className="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="flex items-center gap-4"
            >
              <Logo size="md" />
            </motion.div>
            
            {/* Mobile menu button */}
            <button
              onClick={toggleMobileMenu}
              className="lg:hidden p-2 rounded-lg hover:bg-gray-100"
            >
              {isMobileMenuOpen ? (
                <X className="h-6 w-6 text-gray-600" />
              ) : (
                <Menu className="h-6 w-6 text-gray-600" />
              )}
            </button>

            {/* Desktop header content */}
            <div className="hidden lg:flex items-center gap-6">
              <motion.div 
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="flex items-center gap-2 text-sm text-gray-600"
              >
                <span className="font-medium">{currentUser?.email}</span>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-emerald-50 to-teal-50"
              >
                <Wallet className="h-4 w-4 text-emerald-600" />
                <span className="text-sm font-medium text-emerald-600">
                  {balance.toLocaleString()} ₸
                </span>
              </motion.div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 lg:hidden"
          >
            <div className="fixed inset-0 bg-gray-900/80 backdrop-blur-sm" onClick={toggleMobileMenu} />
            <motion.div
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              transition={{ type: 'spring', damping: 20 }}
              className="fixed inset-0 z-50 w-full h-full bg-white flex flex-col"
            >
              <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200">
                <Logo size="md" />
                <button
                  onClick={toggleMobileMenu}
                  className="p-2 rounded-lg hover:bg-gray-100"
                >
                  <X className="h-6 w-6 text-gray-600" />
                </button>
              </div>
              
              <div className="p-4 border-b border-gray-200 bg-gradient-to-r from-gray-50 to-gray-100">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2 text-sm text-gray-600">
                    <span className="font-medium truncate">{currentUser?.email}</span>
                  </div>
                  <div className="flex items-center gap-2 px-4 py-2 rounded-full bg-gradient-to-r from-emerald-50 to-teal-50">
                    <Wallet className="h-4 w-4 text-emerald-600" />
                    <span className="text-sm font-medium text-emerald-600">
                      {balance.toLocaleString()} ₸
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex-1 overflow-y-auto p-4">
                <nav className="space-y-3">
                  {menuItems.map((item, index) => (
                    <MenuItem key={item.path} item={item} index={index} isMobile={true} />
                  ))}
                </nav>
              </div>

              <div className="p-4 border-t border-gray-200">
                <button
                  onClick={() => {
                    handleLogout();
                    setIsMobileMenuOpen(false);
                  }}
                  className="flex items-center justify-center gap-2 w-full p-4 text-sm font-medium rounded-xl text-white bg-gradient-to-r from-red-600 to-pink-600 hover:from-red-700 hover:to-pink-700 transition-all"
                >
                  <LogOut className="h-5 w-5" />
                  <span>Выйти</span>
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Main layout */}
      <div className="flex min-h-screen">
        {/* Desktop sidebar */}
        <div className="hidden lg:flex lg:flex-col lg:w-80 lg:fixed lg:inset-y-0">
          <div className="flex flex-col flex-grow gap-4 overflow-y-auto bg-white/50 backdrop-blur-md border-r border-gray-200 px-6 pb-4">
            <div className="h-16" /> {/* Spacer for header */}
            <nav className="flex flex-col flex-1 gap-2">
              {menuItems.map((item, index) => (
                <MenuItem key={item.path} item={item} index={index} />
              ))}
            </nav>
            <button
              onClick={handleLogout}
              className="flex items-center gap-3 w-full p-4 text-sm font-medium rounded-xl text-gray-700 hover:bg-white hover:shadow-md transition-all duration-200"
            >
              <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-gradient-to-r from-red-600 to-pink-600 text-white">
                <LogOut className="h-5 w-5" />
              </div>
              <div className="flex flex-col">
                <span className="font-semibold">Выйти</span>
                <span className="text-xs text-gray-500">Завершить сессию</span>
              </div>
            </button>
          </div>
        </div>

        {/* Main content */}
        <main className="flex-1 lg:pl-80">
          <div className="h-16" /> {/* Spacer for header */}
          <div className="px-4 py-6 sm:px-6 lg:px-8">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
} 