import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Bot, Zap, Globe, Database, BarChart, LogIn, UserPlus, Menu, X, ChevronRight, MessageSquare, Shield, Rocket } from 'lucide-react';
import { Logo } from '../components/ui/Logo';

const features = [
  {
    name: 'Мощные AI модели',
    description: 'Используйте передовые модели GPT-4, GPT-3.5 и Claude для создания умных ответов',
    icon: Zap,
  },
  {
    name: 'Простая интеграция',
    description: 'Легко интегрируйте бота с Telegram, вашим сайтом или мобильным приложением',
    icon: Globe,
  },
  {
    name: 'Обучение на данных',
    description: 'Загружайте свои данные для персонализации ответов бота',
    icon: Database,
  },
  {
    name: 'Аналитика',
    description: 'Отслеживайте эффективность бота и получайте инсайты',
    icon: BarChart,
  },
];

const pricingPlans = [
  {
    name: 'Базовый',
    price: '4 990 ₸',
    period: 'в месяц',
    features: [
      'До 100 сообщений в день',
      'Базовые шаблоны ответов',
      'Email поддержка',
    ],
    icon: MessageSquare,
    color: 'from-blue-500 to-cyan-500',
  },
  {
    name: 'Продвинутый',
    price: '12 990 ₸',
    period: 'в месяц',
    features: [
      'Неограниченные сообщения',
      'Расширенные шаблоны',
      'Приоритетная поддержка',
      'Аналитика',
    ],
    icon: Shield,
    color: 'from-indigo-500 to-purple-500',
    popular: true,
  },
  {
    name: 'Бизнес',
    price: '24 990 ₸',
    period: 'в месяц',
    features: [
      'Все функции Продвинутого плана',
      'API доступ',
      'Персональный менеджер',
      'SLA гарантия',
    ],
    icon: Rocket,
    color: 'from-purple-500 to-pink-500',
  },
];

const faqs = [
  {
    question: 'Как создать бота?',
    answer: 'Зарегистрируйтесь, перейдите в панель управления и нажмите "Создать бота". Заполните необходимые поля и настройте параметры бота.',
  },
  {
    question: 'Какие модели AI доступны?',
    answer: 'Мы поддерживаем GPT-4, GPT-3.5-turbo и Claude-2. Каждая модель имеет свои особенности и оптимальна для разных задач.',
  },
  {
    question: 'Как интегрировать бота с Telegram?',
    answer: 'В разделе интеграций введите токен вашего Telegram бота, полученный от @BotFather, и следуйте инструкциям по настройке.',
  },
  {
    question: 'Как добавить бота на сайт?',
    answer: 'В разделе интеграций выберите "Веб-интеграция", скопируйте предоставленный код и вставьте его на свой сайт.',
  },
  {
    question: 'Какие методы оплаты поддерживаются?',
    answer: 'Мы принимаем оплату через Kaspi Pay и банковские карты. Все платежи безопасны и проходят через защищенные каналы.',
  },
];

export default function Home() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const handleCreateBot = () => {
    navigate('/auth/signup');
  };

  const handleLogin = () => {
    navigate('/auth/login');
  };

  return (
    <div className="bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen">
      {/* Header */}
      <header className="fixed w-full top-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-200">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-4 sm:p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <Logo size="md" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Открыть меню</span>
              {mobileMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <a href="#features" className="text-sm font-semibold leading-6 text-gray-900 hover:text-blue-600 transition-colors">
              Возможности
            </a>
            <a href="#pricing" className="text-sm font-semibold leading-6 text-gray-900 hover:text-blue-600 transition-colors">
              Цены
            </a>
            <a href="#faq" className="text-sm font-semibold leading-6 text-gray-900 hover:text-blue-600 transition-colors">
              FAQ
            </a>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end lg:gap-x-4">
            <button
              onClick={handleLogin}
              className="inline-flex items-center gap-2 text-sm font-semibold leading-6 text-gray-900 hover:text-blue-600 transition-colors"
            >
              <LogIn className="w-4 h-4" />
              Войти
            </button>
            <button
              onClick={handleCreateBot}
              className="inline-flex items-center gap-2 rounded-lg bg-gradient-to-r from-blue-600 to-cyan-600 px-4 py-2 text-sm font-semibold text-white shadow-lg hover:shadow-xl transition-all duration-200 hover:from-blue-700 hover:to-cyan-700"
            >
              <UserPlus className="w-4 h-4" />
              Создать бота
            </button>
          </div>
        </nav>
      </header>

      {/* Mobile menu */}
      {mobileMenuOpen && (
        <div className="fixed inset-0 z-[100]">
          <div 
            className="fixed inset-0 bg-gray-900/80 backdrop-blur-sm" 
            onClick={() => setMobileMenuOpen(false)} 
          />
          <div className="fixed inset-0 z-[100] h-full w-full bg-white">
            <div className="flex h-full flex-col overflow-y-auto">
              <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200">
                <a href="/" className="-m-1.5 p-1.5">
                  <Logo size="md" />
                </a>
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                >
                  <span className="sr-only">Закрыть меню</span>
                  <X className="h-6 w-6" />
                </button>
              </div>
              <div className="flex-1 px-4 py-6">
                <div className="flex flex-col space-y-6">
                  <a
                    href="#features"
                    onClick={() => setMobileMenuOpen(false)}
                    className="flex items-center gap-x-4 rounded-xl px-4 py-4 text-lg font-semibold text-gray-900 hover:bg-gray-50 transition-all"
                  >
                    <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-gradient-to-r from-blue-600 to-cyan-600">
                      <Zap className="h-6 w-6 text-white" />
                    </div>
                    Возможности
                  </a>
                  <a
                    href="#pricing"
                    onClick={() => setMobileMenuOpen(false)}
                    className="flex items-center gap-x-4 rounded-xl px-4 py-4 text-lg font-semibold text-gray-900 hover:bg-gray-50 transition-all"
                  >
                    <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-gradient-to-r from-blue-600 to-cyan-600">
                      <Shield className="h-6 w-6 text-white" />
                    </div>
                    Цены
                  </a>
                  <a
                    href="#faq"
                    onClick={() => setMobileMenuOpen(false)}
                    className="flex items-center gap-x-4 rounded-xl px-4 py-4 text-lg font-semibold text-gray-900 hover:bg-gray-50 transition-all"
                  >
                    <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-gradient-to-r from-blue-600 to-cyan-600">
                      <MessageSquare className="h-6 w-6 text-white" />
                    </div>
                    FAQ
                  </a>
                </div>
              </div>
              <div className="border-t border-gray-200 px-4 py-6 space-y-4">
                <button
                  onClick={() => {
                    setMobileMenuOpen(false);
                    handleLogin();
                  }}
                  className="flex w-full items-center justify-center gap-2 rounded-xl bg-gray-50 px-4 py-4 text-lg font-semibold text-gray-900 hover:bg-gray-100 transition-all"
                >
                  <LogIn className="w-6 h-6" />
                  Войти
                </button>
                <button
                  onClick={() => {
                    setMobileMenuOpen(false);
                    handleCreateBot();
                  }}
                  className="flex w-full items-center justify-center gap-2 rounded-xl bg-gradient-to-r from-blue-600 to-cyan-600 px-4 py-4 text-lg font-semibold text-white hover:from-blue-700 hover:to-cyan-700 transition-all"
                >
                  <UserPlus className="w-6 h-6" />
                  Создать бота
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Hero section */}
      <div className="relative isolate pt-14">
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-blue-600 to-cyan-600 opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div className="py-12 sm:py-20 lg:pb-40">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-cyan-600"
              >
                Создайте умного AI-ассистента для вашего бизнеса
              </motion.h1>
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="mt-4 sm:mt-6 text-base sm:text-lg leading-8 text-gray-600 px-4"
              >
                Персонализированный AI-бот, который знает всё о вашем бизнесе и всегда готов помочь вашим клиентам
              </motion.p>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="mt-8 sm:mt-10 flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-x-6"
              >
                <button
                  onClick={handleCreateBot}
                  className="w-full sm:w-auto rounded-xl bg-gradient-to-r from-blue-600 to-cyan-600 px-6 py-3 text-base sm:text-lg font-semibold text-white shadow-lg hover:shadow-xl transition-all duration-200 hover:from-blue-700 hover:to-cyan-700"
                >
                  Создать бота
                </button>
                <a href="#features" className="text-base sm:text-lg font-semibold leading-6 text-gray-900 hover:text-blue-600 transition-colors">
                  Узнать больше <span aria-hidden="true">→</span>
                </a>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* Features section */}
      <div id="features" className="py-12 sm:py-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight text-gray-900"
            >
              Всё необходимое для создания умного бота
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="mt-4 sm:mt-6 text-base sm:text-lg leading-8 text-gray-600 px-4"
            >
              Мы предоставляем полный набор инструментов для создания, обучения и управления вашим AI-ассистентом
            </motion.p>
          </div>
          <div className="mx-auto mt-8 sm:mt-16 max-w-2xl lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-8 sm:gap-y-16 md:grid-cols-2 lg:grid-cols-4">
              {features.map((feature) => (
                <motion.div
                  key={feature.name}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="flex flex-col items-start sm:items-center text-left sm:text-center"
                >
                  <dt className="text-lg font-semibold leading-7 text-gray-900">
                    <div className="mb-4 sm:mb-6 flex h-12 w-12 sm:h-14 sm:w-14 items-center justify-center rounded-xl bg-gradient-to-r from-blue-600 to-cyan-600 text-white shadow-lg">
                      <feature.icon className="h-6 w-6 sm:h-8 sm:w-8" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-sm sm:text-base leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </motion.div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* Pricing section */}
      <div id="pricing" className="py-12 sm:py-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight text-gray-900"
            >
              Простые и понятные тарифы
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="mt-4 sm:mt-6 text-base sm:text-lg leading-8 text-gray-600 px-4"
            >
              Выберите план, который подходит именно вам
            </motion.p>
          </div>
          <div className="mx-auto mt-8 sm:mt-16 grid max-w-lg grid-cols-1 gap-y-6 sm:gap-y-8 lg:max-w-none lg:grid-cols-3 lg:gap-x-8">
            {pricingPlans.map((plan) => (
              <motion.div
                key={plan.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className={`relative flex flex-col rounded-3xl bg-white p-6 sm:p-8 shadow-xl ring-1 ring-gray-200 ${
                  plan.popular ? 'lg:z-10 lg:scale-105' : ''
                }`}
              >
                {plan.popular && (
                  <p className="absolute -top-3 left-1/2 -translate-x-1/2 rounded-full bg-gradient-to-r from-blue-600 to-cyan-600 px-4 py-1 text-sm font-semibold text-white">
                    Популярный выбор
                  </p>
                )}
                <div className="flex items-center justify-between gap-x-4">
                  <h3 className="text-xl sm:text-2xl font-bold tracking-tight text-gray-900">{plan.name}</h3>
                  <div className={`rounded-full p-2 sm:p-2.5 bg-gradient-to-r ${plan.color}`}>
                    <plan.icon className="h-5 w-5 sm:h-6 sm:w-6 text-white" />
                  </div>
                </div>
                <p className="mt-4 sm:mt-6 flex items-baseline gap-x-1">
                  <span className="text-3xl sm:text-4xl font-bold tracking-tight text-gray-900">{plan.price}</span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">{plan.period}</span>
                </p>
                <ul role="list" className="mt-6 sm:mt-8 space-y-3 text-sm sm:text-base leading-6 text-gray-600">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <ChevronRight className="h-5 w-4 sm:h-6 sm:w-5 flex-none text-blue-600" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <button
                  onClick={handleCreateBot}
                  className={`mt-6 sm:mt-8 w-full rounded-xl bg-gradient-to-r ${
                    plan.popular ? 'from-blue-600 to-cyan-600' : 'from-gray-600 to-gray-700'
                  } px-4 py-2.5 sm:py-3 text-center text-sm sm:text-base font-semibold text-white shadow-lg hover:shadow-xl transition-all duration-200`}
                >
                  Начать использовать
                </button>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* FAQ section */}
      <div id="faq" className="py-12 sm:py-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight text-gray-900"
            >
              Часто задаваемые вопросы
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="mt-4 sm:mt-6 text-base sm:text-lg leading-8 text-gray-600 px-4"
            >
              Ответы на популярные вопросы о нашем сервисе
            </motion.p>
          </div>
          <div className="mx-auto mt-8 sm:mt-16 max-w-2xl">
            <dl className="space-y-6 sm:space-y-8">
              {faqs.map((faq, index) => (
                <motion.div
                  key={faq.question}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="px-4"
                >
                  <dt className="text-base sm:text-lg font-semibold leading-7 text-gray-900">{faq.question}</dt>
                  <dd className="mt-2 sm:mt-4 text-sm sm:text-base leading-7 text-gray-600">{faq.answer}</dd>
                </motion.div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="mt-16 sm:mt-32 bg-gray-900">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 py-8 sm:py-12 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            <a href="#" className="text-gray-400 hover:text-gray-300">
              <span className="sr-only">Telegram</span>
              <svg className="h-5 w-5 sm:h-6 sm:w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.223-.535.223l.19-2.72 4.94-4.465c.215-.19-.047-.297-.332-.107l-6.107 3.843-2.627-.816c-.57-.18-.582-.57.12-.848l10.257-3.96c.474-.18.89.107.722.848z" />
              </svg>
            </a>
          </div>
          <div className="mt-4 md:order-1 md:mt-0">
            <p className="text-center text-xs sm:text-sm leading-5 text-gray-400">
              &copy; 2024 QAI. Все права защищены.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
} 