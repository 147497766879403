import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC6rhgmum_aUPa0HxjfbHxHPcDicJOwh_U",
  authDomain: "chatbot-e5532.firebaseapp.com",
  projectId: "chatbot-e5532",
  storageBucket: "chatbot-e5532.firebasestorage.app",
  messagingSenderId: "296316387892",
  appId: "1:296316387892:web:45f302675413fd75814061"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app); 