import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface BotData {
  name: string;
  websiteUrl: string;
  trainingText: string;
  createdAt: string;
  userId: string;
}

const modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'color': [] }, { 'background': [] }],
    ['clean'],
    ['link']
  ],
  clipboard: {
    matchVisual: false
  }
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'indent',
  'color', 'background',
  'link'
];

export default function CreateBot() {
  const [name, setName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [trainingText, setTrainingText] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    websiteUrl: '',
    trainingText: ''
  });

  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
      websiteUrl: '',
      trainingText: ''
    };

    if (!name.trim()) {
      newErrors.name = 'Введите имя бота';
      isValid = false;
    }

    if (websiteUrl.trim()) {
      try {
        new URL(websiteUrl);
      } catch (e) {
        newErrors.websiteUrl = 'Введите корректный URL';
        isValid = false;
      }
    }

    if (!trainingText.trim()) {
      newErrors.trainingText = 'Введите текст для обучения бота';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);

      const botId = `${currentUser!.uid}_${Date.now()}`;
      const botData: BotData = {
        name: name.trim(),
        websiteUrl: websiteUrl.trim(),
        trainingText: trainingText.trim(),
        createdAt: new Date().toISOString(),
        userId: currentUser!.uid
      };

      // Сохраняем данные бота в Firestore
      await setDoc(doc(db, 'bots', botId), botData);

      // Отправляем данные для обучения
      const response = await fetch('https://processtrainingdata-3qe2dgyxxa-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          websiteUrl: websiteUrl.trim(),
          trainingText: trainingText.trim(),
          userId: currentUser!.uid,
          botId
        })
      });

      if (!response.ok) {
        throw new Error('Ошибка при обработке обучающих данных');
      }

      navigate(`/dashboard/integration/${botId}`);
    } catch (error) {
      console.error('Ошибка при создании бота:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 hover:shadow-2xl">
          <div className="px-6 py-8 md:p-8">
            <div className="mb-8">
              <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-600 tracking-tight">
                Создание нового бота
              </h1>
              <p className="mt-2 text-base text-gray-600">
                Заполните форму ниже, чтобы создать нового чат-бота для вашего проекта
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-8">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                {/* Имя бота */}
                <div className="sm:col-span-1 group">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 group-hover:text-blue-600 transition-colors duration-200">
                    Имя бота <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className={`block w-full px-4 py-3 text-base transition-all duration-200 ${
                        errors.name
                          ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                          : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400'
                      } rounded-md focus:outline-none transform hover:translate-x-1`}
                      placeholder="Например: Помощник по продажам"
                    />
                    {errors.name && (
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <svg className="h-5 w-5 text-red-500 animate-pulse" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                      </div>
                    )}
                  </div>
                  {errors.name && (
                    <p className="mt-2 text-sm text-red-600 animate-bounce">{errors.name}</p>
                  )}
                </div>

                {/* URL сайта */}
                <div className="sm:col-span-1 group">
                  <label htmlFor="websiteUrl" className="block text-sm font-medium text-gray-700 group-hover:text-blue-600 transition-colors duration-200">
                    URL сайта <span className="text-gray-400">(необязательно)</span>
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      type="url"
                      id="websiteUrl"
                      value={websiteUrl}
                      onChange={(e) => setWebsiteUrl(e.target.value)}
                      className={`block w-full px-4 py-3 text-base transition-all duration-200 ${
                        errors.websiteUrl
                          ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                          : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400'
                      } rounded-md focus:outline-none transform hover:translate-x-1`}
                      placeholder="https://example.com"
                    />
                    {errors.websiteUrl && (
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <svg className="h-5 w-5 text-red-500 animate-pulse" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                      </div>
                    )}
                  </div>
                  {errors.websiteUrl && (
                    <p className="mt-2 text-sm text-red-600 animate-bounce">{errors.websiteUrl}</p>
                  )}
                </div>
              </div>

              {/* Текст для обучения */}
              <div className="col-span-full group">
                <label htmlFor="trainingText" className="block text-sm font-medium text-gray-700 group-hover:text-blue-600 transition-colors duration-200">
                  Текст для обучения <span className="text-red-500">*</span>
                </label>
                <p className="mt-1 text-sm text-gray-500 group-hover:text-gray-700 transition-colors duration-200">
                  Добавьте информацию, на основе которой бот будет отвечать на вопросы пользователей
                </p>
                <div className="mt-2 transition-all duration-300 transform hover:scale-[1.01]">
                  <div className={`border rounded-lg overflow-hidden transition-all duration-200 ${
                    errors.trainingText
                      ? 'border-red-300'
                      : 'border-gray-300 hover:border-blue-400'
                  }`}>
                    <ReactQuill
                      theme="snow"
                      value={trainingText}
                      onChange={setTrainingText}
                      modules={modules}
                      formats={formats}
                      placeholder="Введите текст для обучения бота..."
                      className="h-[400px] mb-12"
                    />
                  </div>
                  {errors.trainingText && (
                    <p className="mt-2 text-sm text-red-600 animate-bounce">{errors.trainingText}</p>
                  )}
                </div>
              </div>

              {/* Кнопки */}
              <div className="pt-5">
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => navigate('/dashboard')}
                    className="bg-white py-3 px-6 border border-gray-300 rounded-lg shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:-translate-y-1"
                  >
                    Отмена
                  </button>
                  <button
                    type="submit"
                    disabled={loading}
                    className={`inline-flex justify-center py-3 px-6 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue-600 to-cyan-600 hover:from-blue-700 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:-translate-y-1 ${
                      loading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {loading ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Создание...
                      </>
                    ) : (
                      'Создать бота'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
} 