import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pencil, Trash2, Plus, Zap, Share2, AlertTriangle, X, Bot, MessageSquare } from 'lucide-react';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import { PencilIcon, TrashIcon, CodeBracketIcon } from '@heroicons/react/24/outline';
import { useNotification } from '../../contexts/NotificationContext';
import { motion, AnimatePresence } from 'framer-motion';

interface DeleteModalProps {
  isOpen: boolean;
  botName: string;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, botName, onClose, onConfirm }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black z-40"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed inset-x-4 top-[50%] -translate-y-[50%] sm:left-[50%] sm:-translate-x-[50%] sm:w-[450px] bg-white rounded-xl shadow-2xl z-50 overflow-hidden"
          >
            <div className="p-6">
              <div className="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center mx-auto mb-4">
                <AlertTriangle className="w-6 h-6 text-red-600" />
              </div>
              <h3 className="text-xl font-semibold text-gray-900 text-center mb-2">
                Удаление бота
              </h3>
              <p className="text-gray-500 text-center mb-6">
                Вы уверены, что хотите удалить бота "<span className="font-medium text-gray-900">{botName}</span>"?
                Это действие нельзя будет отменить.
              </p>
              <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
                <button
                  onClick={onClose}
                  className="flex-1 px-4 py-3 bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium rounded-lg transition-colors"
                >
                  Отмена
                </button>
                <button
                  onClick={onConfirm}
                  className="flex-1 px-4 py-3 bg-red-600 hover:bg-red-700 text-white font-medium rounded-lg transition-colors"
                >
                  Удалить
                </button>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

interface Bot {
  id: string;
  name: string;
  websiteUrl: string;
  createdAt: string;
}

export default function BotsList() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [bots, setBots] = useState<Bot[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { showNotification } = useNotification();
  const [deleteModal, setDeleteModal] = useState<{ isOpen: boolean; bot: Bot | null }>({
    isOpen: false,
    bot: null
  });

  useEffect(() => {
    if (currentUser) {
      loadBots();
    }
  }, [currentUser]);

  const loadBots = async () => {
    if (!currentUser) return;

    try {
      setLoading(true);
      const botsQuery = query(
        collection(db, 'bots'),
        where('userId', '==', currentUser.uid)
      );
      
      const querySnapshot = await getDocs(botsQuery);
      const botsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Bot[];

      botsData.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      setBots(botsData);
    } catch (err) {
      console.error('Ошибка при загрузке ботов:', err);
      setError('Не удалось загрузить ботов');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (botId: string) => {
    console.log('Переход к редактированию бота:', botId);
    navigate(`/dashboard/integration/${botId}`, { state: { defaultTab: 'edit' } });
  };

  const handleIntegration = (botId: string) => {
    navigate(`/dashboard/integration/${botId}`);
  };

  const handleDelete = (bot: Bot) => {
    setDeleteModal({ isOpen: true, bot });
  };

  const confirmDelete = async () => {
    if (!deleteModal.bot) return;

    try {
      await deleteDoc(doc(db, 'bots', deleteModal.bot.id));
      showNotification('success', 'Бот успешно удален');
      await loadBots();
    } catch (err) {
      showNotification('error', 'Ошибка при удалении бота');
      console.error('Ошибка при удалении бота:', err);
    } finally {
      setDeleteModal({ isOpen: false, bot: null });
    }
  };

  const handleCreateBot = () => {
    navigate('/dashboard/create');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="text-center py-12 bg-red-50 rounded-xl shadow-lg">
            <p className="text-red-600 text-lg">{error}</p>
            <button
              onClick={loadBots}
              className="mt-4 inline-flex items-center px-6 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue-600 to-cyan-600 hover:from-blue-700 hover:to-cyan-700 transition-all duration-200 transform hover:-translate-y-1"
            >
              Попробовать снова
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-4 sm:py-8 px-4 sm:px-6 lg:px-8">
      <DeleteModal
        isOpen={deleteModal.isOpen}
        botName={deleteModal.bot?.name || ''}
        onClose={() => setDeleteModal({ isOpen: false, bot: null })}
        onConfirm={confirmDelete}
      />
      
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-xl shadow-xl overflow-hidden">
          <div className="px-4 py-6 sm:px-6 md:p-8">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 sm:gap-8 mb-6 sm:mb-8">
              <div>
                <h1 className="text-2xl sm:text-3xl md:text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-600 tracking-tight">
                  Мои боты
                </h1>
                <p className="mt-2 text-sm sm:text-base text-gray-600">
                  Управляйте вашими чат-ботами
                </p>
              </div>
              <button
                onClick={handleCreateBot}
                className="inline-flex items-center justify-center w-full sm:w-auto px-4 sm:px-6 py-2.5 sm:py-3 border border-transparent rounded-lg shadow-sm text-sm sm:text-base font-medium text-white bg-gradient-to-r from-blue-600 to-cyan-600 hover:from-blue-700 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
              >
                <Plus className="h-5 w-5 mr-2" />
                Создать бота
              </button>
            </div>

            <div className="grid gap-4">
              {bots.map((bot) => (
                <div
                  key={bot.id}
                  className="flex flex-col sm:flex-row sm:items-center justify-between p-4 sm:p-6 bg-white border rounded-lg shadow-sm hover:shadow-md transition-all duration-200 transform hover:-translate-y-1 hover:border-blue-200 group"
                >
                  <div className="flex flex-col flex-grow mb-4 sm:mb-0">
                    <div className="flex items-center">
                      <Zap className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                      <span className="text-base sm:text-lg font-medium text-gray-900 group-hover:text-blue-600 transition-colors duration-200 truncate">
                        {bot.name}
                      </span>
                    </div>
                    {bot.websiteUrl && (
                      <span className="text-sm text-gray-500 mt-1 ml-7 truncate">
                        {bot.websiteUrl}
                      </span>
                    )}
                    <span className="text-xs text-gray-400 mt-2 ml-7">
                      Создан: {new Date(bot.createdAt).toLocaleDateString()}
                    </span>
                  </div>
                  <div className="flex justify-end gap-2 sm:gap-3 ml-7 sm:ml-4">
                    <button
                      type="button"
                      onClick={() => navigate(`/dashboard/integration/${bot.id}`, { state: { defaultTab: 'testing' } })}
                      className="p-2 sm:p-3 text-gray-600 hover:text-blue-600 rounded-lg hover:bg-blue-50 transition-all duration-200"
                      title="Тестирование"
                    >
                      <Bot className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleEdit(bot.id)}
                      className="p-2 sm:p-3 text-blue-600 hover:text-blue-800 rounded-lg hover:bg-blue-50 transition-all duration-200"
                      title="Редактирование"
                    >
                      <PencilIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(bot)}
                      className="p-2 sm:p-3 text-red-600 hover:text-red-800 rounded-lg hover:bg-red-50 transition-all duration-200"
                      title="Удалить"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ))}
              
              {bots.length === 0 && (
                <div className="text-center py-12">
                  <Bot className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-4 text-lg font-medium text-gray-900">У вас пока нет ботов</h3>
                  <p className="mt-2 text-sm text-gray-500">Создайте своего первого бота, чтобы начать работу</p>
                  <button
                    onClick={handleCreateBot}
                    className="mt-6 inline-flex items-center px-6 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue-600 to-cyan-600 hover:from-blue-700 hover:to-cyan-700 transition-all duration-200"
                  >
                    <Plus className="h-5 w-5 mr-2" />
                    Создать бота
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 