import React from 'react';
import { Bot } from 'lucide-react';

interface LogoProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

export function Logo({ className = '', size = 'md' }: LogoProps) {
  const sizes = {
    sm: {
      container: 'h-8 w-8',
      icon: 'h-4 w-4',
      text: 'text-lg'
    },
    md: {
      container: 'h-10 w-10',
      icon: 'h-5 w-5',
      text: 'text-xl'
    },
    lg: {
      container: 'h-12 w-12',
      icon: 'h-6 w-6',
      text: 'text-2xl'
    }
  };

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <div className={`flex items-center justify-center rounded-xl bg-gradient-to-r from-blue-600 to-cyan-600 ${sizes[size].container}`}>
        <Bot className={`${sizes[size].icon} text-white`} />
      </div>
      <span className={`font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-cyan-600 ${sizes[size].text}`}>
        QAI
      </span>
    </div>
  );
} 