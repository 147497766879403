import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Copy, Send, Bot, Globe, MessageSquare, Check, Loader2, Zap, Shield, Layout, Palette, Eye, Monitor, Smartphone, X, Settings, ArrowRight, Bell, Save, Pencil, HelpCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TelegramBot } from '../telegram/telegram-bot';
import { useToast } from '@chakra-ui/react';

interface IntegrationData {
  telegramToken?: string;
  widgetSettings: {
    primaryColor: string;
    secondaryColor: string;
    buttonColor: string;
    iconColor: string;
    headerText: string;
    buttonPosition: 'left' | 'right';
    buttonStyle: 'rounded' | 'square';
    welcomeMessage: string;
    fontSize: string;
  };
}

const defaultWidgetSettings: IntegrationData['widgetSettings'] = {
  primaryColor: '#2563eb',
  secondaryColor: '#0891b2',
  buttonColor: '#2563eb',
  iconColor: '#ffffff',
  headerText: 'AI Чат',
  buttonPosition: 'right' as const,
  buttonStyle: 'rounded' as const,
  welcomeMessage: 'Здравствуйте! Чем могу помочь?',
  fontSize: '14px'
};

interface Message {
  id: string;
  role: 'user' | 'assistant' | 'typing';
  content: string;
  timestamp: Date;
}

interface Notification {
  id: string;
  type: 'success' | 'error';
  message: string;
}

const TypingIndicator = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (prev === '...') return '';
        return prev + '.';
      });
    }, 500); // Добавляем интервал в миллисекундах (например, 500ms)

    return () => clearInterval(interval);
  }, []); // Пустой массив зависимостей для useEffect

  return (
    <div className="flex items-center gap-1">
      <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" style={{ animationDelay: '0ms' }} />
      <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" style={{ animationDelay: '200ms' }} />
      <div className="w-2 h-2 rounded-full bg-gray-400 animate-bounce" style={{ animationDelay: '400ms' }} />
    </div>
  );
};

// Добавляем тип для window
declare global {
  interface Window {
    currentBot?: TelegramBot;
  }
}

export default function Integration() {
  const { botId } = useParams();
  const { currentUser } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultTab = location.state?.defaultTab || searchParams.get('tab') || 'testing';

  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);
  const [widgetColor, setWidgetColor] = useState('#6366F1');
  const [telegramToken, setTelegramToken] = useState('');
  const [saving, setSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [copied, setCopied] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [showPreviewHint, setShowPreviewHint] = useState(true);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [widgetSettings, setWidgetSettings] = useState<IntegrationData['widgetSettings']>(defaultWidgetSettings);
  const [previewMode, setPreviewMode] = useState<'desktop' | 'mobile'>('desktop');
  const [name, setName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [trainingText, setTrainingText] = useState('');
  const [editErrors, setEditErrors] = useState({
    name: '',
    websiteUrl: '',
    trainingText: ''
  });
  const [editLoading, setEditLoading] = useState(false);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [showTelegramGuide, setShowTelegramGuide] = useState(false);
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ align: [] }],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'indent',
    'align'
  ];

  const toast = useToast();

  useEffect(() => {
    const loadSettings = async () => {
      if (!botId || !currentUser) return;

      try {
        // Сначала пытаемся загрузить пользовательские настройки
        const userSettingsRef = doc(db, 'userWidgetSettings', `${currentUser.uid}_${botId}`);
        const userSettingsSnap = await getDoc(userSettingsRef);

        if (userSettingsSnap.exists()) {
          const data = userSettingsSnap.data();
          setWidgetSettings({
            ...defaultWidgetSettings,
            ...data.settings
          });
        } else {
          // Если пользовательских настроек нет, загружаем из документа бота
          const botRef = doc(db, 'bots', botId);
          const botSnap = await getDoc(botRef);

          if (botSnap.exists()) {
            const data = botSnap.data();
            setName(data.name || '');
            setWebsiteUrl(data.websiteUrl || '');
            setTrainingText(data.trainingText || '');
            setTelegramToken(data.telegramToken || '');
            setWidgetColor(data.integrationData?.widgetColor || '#6366F1');
            
            // Проверяем, есть ли настройки виджета в документе бота
            if (data.widgetSettings) {
              setWidgetSettings({
                ...defaultWidgetSettings,
                ...data.widgetSettings
              });
              
              // Мигрируем настройки в новую коллекцию
              await setDoc(userSettingsRef, {
                userId: currentUser.uid,
                botId,
                settings: {
                  ...defaultWidgetSettings,
                  ...data.widgetSettings
                },
                updatedAt: serverTimestamp()
              });
            }
          }
        }
      } catch (error) {
        console.error('Ошибка при загрузке настроек:', error);
        showNotification('error', 'Ошибка при загрузке настроек');
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, [botId, currentUser]);

  const generateId = () => Math.random().toString(36).substr(2, 9);

  const addTypingEffect = (fullMessage: string) => {
    const words = fullMessage.split(' ');
    let currentText = '';
    let wordIndex = 0;

    const typingMessageId = generateId();
    setMessages(prev => [...prev, {
      id: typingMessageId,
      role: 'typing',
      content: '•••',
      timestamp: new Date()
    }]);

    const typeNextWord = () => {
      if (wordIndex < words.length) {
        currentText += (wordIndex === 0 ? '' : ' ') + words[wordIndex];
        setMessages(prev => prev.map(msg => 
          msg.id === typingMessageId 
            ? { ...msg, content: currentText + '|' }
            : msg
        ));
        wordIndex++;
        
        const delay = Math.random() * 100 + 50;
        setTypingTimeout(setTimeout(typeNextWord, delay));
      } else {
        setMessages(prev => prev.map(msg =>
          msg.id === typingMessageId
            ? { ...msg, role: 'assistant', content: currentText }
            : msg
        ));
      }
    };

    typeNextWord();
  };

  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  const simulateTyping = (message: string) => {
    const typingMessageId = generateId();
    setMessages(prev => [...prev, {
      id: typingMessageId,
      role: 'typing',
      content: '',
      timestamp: new Date()
    }]);

    const typingDuration = Math.random() * 1000 + 2000;

    setTimeout(() => {
      setMessages(prev => prev.map(msg =>
        msg.id === typingMessageId
          ? { ...msg, role: 'assistant', content: message }
          : msg
      ));
    }, typingDuration);
  };

  const handleQuestionSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!question.trim() || !botId) return;

    const userMessageId = generateId();
    const userMessage: Message = {
      id: userMessageId,
      role: 'user',
      content: question,
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setQuestion('');
    setLoading(true);

    try {
      const response = await fetch('https://chat-3qe2dgyxxa-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          question,
          botId,
          userId: currentUser?.uid
        })
      });

      if (!response.ok) {
        throw new Error(`API вернул статус: ${response.status}`);
      }

      const data = await response.json();
      
      if (!data.answer) {
        throw new Error('Неверный формат ответа от API');
      }

      simulateTyping(data.answer);
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
      setMessages(prev => [...prev, {
        id: generateId(),
        role: 'assistant',
        content: 'Извините, произошла ошибка при обработке вашего запроса. Пожалуйста, попробуйте позже.',
        timestamp: new Date()
      }]);
    } finally {
      setLoading(false);
    }
  };

  const showNotification = (type: 'success' | 'error', message: string) => {
    const id = generateId();
    setNotifications(prev => [...prev, { id, type, message }]);
    setTimeout(() => {
      setNotifications(prev => prev.filter(n => n.id !== id));
    }, 3000);
  };

  const handleTelegramTokenSave = async () => {
    if (!telegramToken || !botId) {
      toast({
        title: 'Ошибка!',
        description: 'Введите токен Telegram бота',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setSaving(true);

    try {
      // Сохраняем botId в localStorage
      localStorage.setItem('currentBotId', botId);
      console.log('Сохранен botId в localStorage:', botId);

      // Сохраняем токен в Firestore
      const botRef = doc(db, 'bots', botId);
      await updateDoc(botRef, {
        telegramToken: telegramToken,
        updatedAt: serverTimestamp()
      });

      // Создаем экземпляр бота
      const bot = new TelegramBot(telegramToken);
      window.currentBot = bot;

      // Подключаемся к WebSocket серверу
      await bot.connect();

      toast({
        title: 'Успех!',
        description: 'Токен Telegram бота успешно сохранен и подключен.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

    } catch (error: any) {
      console.error('Ошибка при сохранении токена:', error);
      toast({
        title: 'Ошибка!',
        description: error.message || 'Произошла ошибка при сохранении токена',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSaving(false);
    }
  };

  const generateWidgetCode = () => {
    if (!botId) {
      console.error('botId не найден при генерации кода виджета');
      return '<!-- Ошибка: botId не найден -->';
    }

    console.log('Генерация кода виджета с botId:', botId);
    return `<!-- Начало кода виджета QAI -->
<script src="https://telegram-bot-ws-296316387892.us-central1.run.app/static/widget/widget.js"></script>
<script>
// Сохраняем botId в localStorage перед инициализацией
localStorage.setItem('currentBotId', "${botId}");

qaiInit({
    botId: "${botId}",
    websocket: {
        url: "wss://telegram-bot-ws-296316387892.us-central1.run.app/ws?botId=${botId}"
    },
    theme: {
        primaryColor: "${widgetSettings.primaryColor}",
        secondaryColor: "${widgetSettings.secondaryColor}",
        buttonColor: "${widgetSettings.buttonColor}",
        iconColor: "${widgetSettings.iconColor}",
        title: "${widgetSettings.headerText}",
        fontSize: "${widgetSettings.fontSize}",
        buttonPosition: "${widgetSettings.buttonPosition}",
        buttonStyle: "${widgetSettings.buttonStyle}",
        welcomeMessage: "${widgetSettings.welcomeMessage}"
    },
    useEmbeddedSettings: true
});
</script>
<!-- Конец кода виджета QAI -->`;
  };

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(generateWidgetCode());
      setCopied(true);
      showNotification('success', 'Код виджета скопирован в буфер обмена');
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      showNotification('error', 'Не удалось скопировать код');
    }
  };

  const handleWidgetSettingChange = (key: keyof typeof widgetSettings, value: string) => {
    setWidgetSettings(prev => ({
      ...prev,
      [key]: value
    }));
    
    // Автоматически сохраняем настройки при изменении с небольшой задержкой
    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }
    
    saveTimeoutRef.current = setTimeout(() => {
      handleSaveSettings();
    }, 1000); // Задержка в 1 секунду перед сохранением
  };

  const handleSaveSettings = async () => {
    if (!botId || !currentUser) return;

    try {
      setSaving(true);
      
      // Сохраняем настройки в отдельной коллекции для каждого пользователя
      await setDoc(doc(db, 'userWidgetSettings', `${currentUser.uid}_${botId}`), {
        userId: currentUser.uid,
        botId,
        settings: widgetSettings,
        updatedAt: serverTimestamp()
      });
      
      // Также сохраняем ссылку на настройки в документе бота для обратной совместимости
      await setDoc(doc(db, 'bots', botId), {
        telegramToken,
        widgetSettingsRef: `userWidgetSettings/${currentUser.uid}_${botId}`,
        updatedAt: serverTimestamp()
      }, { merge: true });
      
      setSaveSuccess(true);
      setTimeout(() => setSaveSuccess(false), 2000);
    } catch (error) {
      console.error('Ошибка при сохранении настроек:', error);
    } finally {
      setSaving(false);
    }
  };

  const validateEditForm = () => {
    let isValid = true;
    const newErrors = {
      name: '',
      websiteUrl: '',
      trainingText: ''
    };

    if (!name.trim()) {
      newErrors.name = 'Введите имя бота';
      isValid = false;
    }

    if (websiteUrl.trim()) {
      try {
        new URL(websiteUrl);
      } catch (e) {
        newErrors.websiteUrl = 'Введите корректный URL';
        isValid = false;
      }
    }

    if (!trainingText.trim()) {
      newErrors.trainingText = 'Введите текст для обучения бота';
      isValid = false;
    }

    setEditErrors(newErrors);
    return isValid;
  };

  // Функция для очистки текста от HTML-тегов и специальных символов
  const cleanHtmlText = (text: string): string => {
    return text
      .replace(/<[^>]*>/g, '') // Удаляем HTML теги
      .replace(/&nbsp;/g, ' ') // Заменяем неразрывные пробелы
      .replace(/&[a-z]+;/g, ' ') // Заменяем HTML entities
      .replace(/\s+/g, ' ') // Убираем лишние пробелы
      .replace(/[^\wа-яА-ЯёЁ\s.,!?-]/g, ' ') // Оставляем только буквы, цифры и базовую пунктуацию
      .replace(/\s+/g, ' ') // Повторно убираем лишние пробелы
      .trim();
  };

  const handleEditSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateEditForm() || !botId) {
      return;
    }

    try {
      setEditLoading(true);

      // Очищаем обучающий текст от HTML-тегов
      const cleanTrainingText = cleanHtmlText(trainingText);

      await updateDoc(doc(db, 'bots', botId), {
        name: name.trim(),
        websiteUrl: websiteUrl.trim(),
        trainingText: cleanTrainingText,
        updatedAt: new Date().toISOString()
      });

      const response = await fetch('https://processtrainingdata-3qe2dgyxxa-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          websiteUrl: websiteUrl.trim(),
          trainingText: cleanTrainingText,
          userId: currentUser!.uid,
          botId
        })
      });

      if (!response.ok) {
        throw new Error('Ошибка при обработке обучающих данных');
      }

      showNotification('success', 'Бот успешно обновлен');
    } catch (error) {
      console.error('Ошибка при обновлении бота:', error);
      showNotification('error', 'Ошибка при обновлении бота');
    } finally {
      setEditLoading(false);
    }
  };

  const TelegramGuideModal = () => {
    if (!showTelegramGuide) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4 backdrop-blur-sm">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          className="bg-white rounded-xl shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto"
        >
          <div className="sticky top-0 z-10 bg-white border-b border-gray-200">
            <div className="flex items-center justify-between p-6">
              <div>
                <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-cyan-600">
                  Подробная инструкция по интеграции Telegram бота
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  Пошаговое руководство по подключению и использованию бота в Telegram
                </p>
              </div>
              <button
                onClick={() => setShowTelegramGuide(false)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X className="w-6 h-6 text-gray-500" />
              </button>
            </div>
          </div>

          <div className="p-6 space-y-8">
            <section className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                <div className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">
                  1
                </div>
                Создание бота в Telegram
              </h3>
              <div className="ml-10 space-y-4">
                <div className="bg-blue-50 border border-blue-100 rounded-lg p-4">
                  <div className="flex items-start gap-4">
                    <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-blue-600 text-white flex items-center justify-center">
                      <MessageSquare className="w-6 h-6" />
                    </div>
                    <div className="space-y-2">
                      <p className="text-blue-900 font-medium">Шаг 1: Найдите @BotFather</p>
                      <p className="text-blue-800 text-sm">
                        Откройте Telegram и найдите официального бота @BotFather. Это единственный бот, 
                        который может создавать новых ботов.
                      </p>
                      <a 
                        href="https://t.me/BotFather"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-sm text-blue-600 hover:text-blue-700 bg-blue-50 px-3 py-1 rounded-full"
                      >
                        <ArrowRight className="w-4 h-4 mr-1" />
                        Открыть @BotFather
                      </a>
                    </div>
                  </div>
                </div>

                <div className="bg-green-50 border border-green-100 rounded-lg p-4">
                  <div className="flex items-start gap-4">
                    <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-green-600 text-white flex items-center justify-center">
                      <Bot className="w-6 h-6" />
                    </div>
                    <div className="space-y-2">
                      <p className="text-green-900 font-medium">Шаг 2: Создайте нового бота</p>
                      <div className="space-y-2 text-green-800 text-sm">
                        <p>1. Отправьте команду /newbot</p>
                        <p>2. Введите отображаемое имя бота (например, "My Company Assistant")</p>
                        <p>3. Придумайте username для бота (должен заканчиваться на 'bot')</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-purple-50 border border-purple-100 rounded-lg p-4">
                  <div className="flex items-start gap-4">
                    <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-purple-600 text-white flex items-center justify-center">
                      <Shield className="w-6 h-6" />
                    </div>
                    <div className="space-y-2">
                      <p className="text-purple-900 font-medium">Шаг 3: Получите токен</p>
                      <p className="text-purple-800 text-sm">
                        После создания бота, @BotFather отправит вам токен. Это строка вида:
                        <code className="block mt-2 p-2 bg-white rounded border border-purple-200 font-mono text-sm">
                          123456789:ABCdefGHIjklMNOpqrsTUVwxyz
                        </code>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                <div className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">
                  2
                </div>
                Настройка бота на платформе
              </h3>
              <div className="ml-10 space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="bg-cyan-50 border border-cyan-100 rounded-lg p-4">
                    <h4 className="font-medium text-cyan-900 mb-2">Вставьте токен</h4>
                    <p className="text-cyan-800 text-sm">
                      Скопируйте токен, полученный от @BotFather, и вставьте его в поле "Токен Telegram бота" 
                      на этой странице.
                    </p>
                  </div>
                  <div className="bg-teal-50 border border-teal-100 rounded-lg p-4">
                    <h4 className="font-medium text-teal-900 mb-2">Сохраните изменения</h4>
                    <p className="text-teal-800 text-sm">
                      Нажмите кнопку "Сохранить токен". После этого бот будет автоматически подключен 
                      к нашей платформе.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                <div className="flex-shrink-0 w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">
                  3
                </div>
                Использование бота
              </h3>
              <div className="ml-10">
                <div className="bg-gradient-to-r from-blue-50 to-cyan-50 rounded-lg p-6">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                    <div className="flex flex-col items-center text-center p-4 bg-white rounded-lg shadow-sm">
                      <MessageSquare className="w-8 h-8 text-blue-600 mb-2" />
                      <h4 className="font-medium text-gray-900 mb-1">Начало работы</h4>
                      <p className="text-sm text-gray-600">
                        Найдите вашего бота по username и отправьте команду /start
                      </p>
                    </div>
                    <div className="flex flex-col items-center text-center p-4 bg-white rounded-lg shadow-sm">
                      <Zap className="w-8 h-8 text-blue-600 mb-2" />
                      <h4 className="font-medium text-gray-900 mb-1">Быстрые ответы</h4>
                      <p className="text-sm text-gray-600">
                        Бот мгновенно отвечает на вопросы пользователей
                      </p>
                    </div>
                    <div className="flex flex-col items-center text-center p-4 bg-white rounded-lg shadow-sm">
                      <Settings className="w-8 h-8 text-blue-600 mb-2" />
                      <h4 className="font-medium text-gray-900 mb-1">Умный диалог</h4>
                      <p className="text-sm text-gray-600">
                        Поддерживает контекст разговора и помнит предыдущие вопросы
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section>
              <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-6">
                <h3 className="text-lg font-semibold text-yellow-900 mb-4 flex items-center gap-2">
                  <Shield className="w-6 h-6 text-yellow-600" />
                  Важные замечания по безопасности
                </h3>
                <div className="ml-8 space-y-3">
                  <div className="flex items-start gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-yellow-500 mt-2" />
                    <p className="text-yellow-800">
                      Храните токен в безопасном месте и никогда не передавайте его третьим лицам
                    </p>
                  </div>
                  <div className="flex items-start gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-yellow-500 mt-2" />
                    <p className="text-yellow-800">
                      При подозрении на компрометацию токена, немедленно создайте нового бота
                    </p>
                  </div>
                  <div className="flex items-start gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-yellow-500 mt-2" />
                    <p className="text-yellow-800">
                      Регулярно проверяйте активность бота и его настройки
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowTelegramGuide(false)}
                className="px-6 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
              >
                Закрыть
              </button>
              <a
                href="https://t.me/BotFather"
                target="_blank"
                rel="noopener noreferrer"
                className="px-6 py-2 bg-gradient-to-r from-blue-600 to-cyan-600 hover:from-blue-700 hover:to-cyan-700 text-white rounded-lg transition-colors inline-flex items-center gap-2"
              >
                <MessageSquare className="w-4 h-4" />
                Открыть @BotFather
              </a>
            </div>
          </div>
        </motion.div>
      </div>
    );
  };

  if (!botId) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="text-center py-12">
            <p className="text-red-600">Ошибка: ID бота не найден</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8 lg:py-12">
      <TelegramGuideModal />
      <div className="bg-white rounded-xl shadow-xl overflow-hidden">
        <div className="p-4 sm:p-6">
          <div className="text-center mb-6 sm:mb-10">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-cyan-600 tracking-tight mb-3 sm:mb-4">
              Интеграция бота
            </h1>
            <p className="text-base sm:text-lg text-gray-600 max-w-2xl mx-auto">
              Настройте и протестируйте вашего бота
            </p>
          </div>

          <Tabs defaultValue={defaultTab} className="w-full">
            <TabsList className="flex flex-col sm:flex-row w-full bg-gradient-to-r from-blue-50 to-cyan-50 p-1.5 rounded-xl gap-2 mb-6 sm:mb-8">
              <TabsTrigger 
                value="testing" 
                className="flex-1 py-2.5 sm:py-3 data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=active]:shadow-md data-[state=active]:scale-[1.02] transition-all duration-200 rounded-lg font-medium"
              >
                <Bot className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
                Тестирование
              </TabsTrigger>
              <TabsTrigger 
                value="edit"
                className="flex-1 py-2.5 sm:py-3 data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=active]:shadow-md data-[state=active]:scale-[1.02] transition-all duration-200 rounded-lg font-medium"
              >
                <Pencil className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
                Редактирование
              </TabsTrigger>
              <TabsTrigger 
                value="widget"
                className="flex-1 py-2.5 sm:py-3 data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=active]:shadow-md data-[state=active]:scale-[1.02] transition-all duration-200 rounded-lg font-medium"
              >
                <Globe className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
                Виджет
              </TabsTrigger>
              <TabsTrigger 
                value="telegram"
                className="flex-1 py-2.5 sm:py-3 data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=active]:shadow-md data-[state=active]:scale-[1.02] transition-all duration-200 rounded-lg font-medium"
              >
                <MessageSquare className="w-4 h-4 sm:w-5 sm:h-5 mr-2" />
                Telegram
              </TabsTrigger>
            </TabsList>

            <TabsContent value="testing">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-4 sm:p-6">
                  <div className="mb-6">
                    <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                      <Bot className="w-5 h-5 text-blue-600" />
                      Тестирование бота
                    </h3>
                    <p className="text-sm text-gray-600 mt-1">
                      Протестируйте работу бота перед интеграцией
                    </p>
                  </div>

                  <div className="bg-gray-50 rounded-xl overflow-hidden">
                    <div className="h-[400px] sm:h-[500px] overflow-y-auto p-4">
                      {messages.map((message) => (
                        <div
                          key={message.id}
                          className={`flex ${
                            message.role === 'user' ? 'justify-end' : 'justify-start'
                          } mb-4`}
                        >
                          <div
                            className={`max-w-[80%] sm:max-w-[70%] p-3 rounded-lg ${
                              message.role === 'user'
                                ? 'bg-blue-600 text-white'
                                : 'bg-gray-100 text-gray-900'
                            }`}
                          >
                            {message.content}
                          </div>
                        </div>
                      ))}
                      {loading && (
                        <div className="flex justify-start mb-4">
                          <div className="bg-gray-100 rounded-lg p-3">
                            <div className="flex items-center gap-2">
                              <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" />
                              <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }} />
                              <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="p-4 border-t border-gray-100">
                      <form onSubmit={handleQuestionSubmit} className="relative">
                        <textarea
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                          placeholder="Введите ваш вопрос..."
                          className="w-full px-4 py-3 pr-12 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 resize-none text-sm sm:text-base"
                          rows={2}
                        />
                        <button
                          type="submit"
                          disabled={loading || !question.trim()}
                          className="absolute right-2 bottom-2 p-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 transform hover:-translate-y-1"
                        >
                          {loading ? (
                            <Loader2 className="w-5 h-5 animate-spin" />
                          ) : (
                            <Send className="w-5 h-5" />
                          )}
                        </button>
                      </form>
                    </div>
                  </div>

                  <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="bg-blue-50 rounded-lg p-4">
                      <h4 className="text-sm font-medium text-blue-900 mb-2 flex items-center gap-2">
                        <Zap className="w-4 h-4" />
                        Советы по тестированию
                      </h4>
                      <ul className="text-sm text-blue-800 space-y-2">
                        <li className="flex items-start gap-2">
                          <div className="w-1 h-1 rounded-full bg-blue-400 mt-2" />
                          <span>Задавайте разные типы вопросов</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <div className="w-1 h-1 rounded-full bg-blue-400 mt-2" />
                          <span>Проверьте ответы на сложные вопросы</span>
                        </li>
                      </ul>
                    </div>

                    <div className="bg-green-50 rounded-lg p-4">
                      <h4 className="text-sm font-medium text-green-900 mb-2 flex items-center gap-2">
                        <Shield className="w-4 h-4" />
                        Возможности бота
                      </h4>
                      <ul className="text-sm text-green-800 space-y-2">
                        <li className="flex items-start gap-2">
                          <div className="w-1 h-1 rounded-full bg-green-400 mt-2" />
                          <span>Мгновенные ответы на вопросы</span>
                        </li>
                        <li className="flex items-start gap-2">
                          <div className="w-1 h-1 rounded-full bg-green-400 mt-2" />
                          <span>Поддержка диалогов</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>

            <TabsContent value="edit">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-4 sm:p-6">
                  <div className="mb-6">
                    <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                      <Pencil className="w-5 h-5 text-blue-600" />
                      Редактирование бота
                    </h3>
                    <p className="text-sm text-gray-600 mt-1">
                      Измените настройки вашего бота
                    </p>
                  </div>

                  <form onSubmit={handleEditSubmit} className="space-y-8">
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                      {/* Имя бота */}
                      <div className="sm:col-span-1 group">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 group-hover:text-blue-600 transition-colors duration-200">
                          Имя бота <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className={`block w-full px-4 py-3 text-base transition-all duration-200 ${
                              editErrors.name
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                                : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400'
                            } rounded-md focus:outline-none transform hover:translate-x-1`}
                            placeholder="Например: Помощник по продажам"
                          />
                          {editErrors.name && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <svg className="h-5 w-5 text-red-500 animate-pulse" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                            </div>
                          )}
                        </div>
                        {editErrors.name && (
                          <p className="mt-2 text-sm text-red-600 animate-bounce">{editErrors.name}</p>
                        )}
                      </div>

                      {/* URL сайта */}
                      <div className="sm:col-span-1 group">
                        <label htmlFor="websiteUrl" className="block text-sm font-medium text-gray-700 group-hover:text-blue-600 transition-colors duration-200">
                          URL сайта <span className="text-gray-400">(необязательно)</span>
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="url"
                            id="websiteUrl"
                            value={websiteUrl}
                            onChange={(e) => setWebsiteUrl(e.target.value)}
                            className={`block w-full px-4 py-3 text-base transition-all duration-200 ${
                              editErrors.websiteUrl
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                                : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400'
                            } rounded-md focus:outline-none transform hover:translate-x-1`}
                            placeholder="https://example.com"
                          />
                          {editErrors.websiteUrl && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <svg className="h-5 w-5 text-red-500 animate-pulse" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                            </div>
                          )}
                        </div>
                        {editErrors.websiteUrl && (
                          <p className="mt-2 text-sm text-red-600 animate-bounce">{editErrors.websiteUrl}</p>
                        )}
                      </div>
                    </div>

                    {/* Текст для обучения */}
                    <div className="col-span-full group">
                      <label htmlFor="trainingText" className="block text-sm font-medium text-gray-700 group-hover:text-blue-600 transition-colors duration-200">
                        Текст для обучения <span className="text-red-500">*</span>
                      </label>
                      <p className="mt-1 text-sm text-gray-500 group-hover:text-gray-700 transition-colors duration-200">
                        Добавьте информацию, на основе которой бот будет отвечать на вопросы пользователей
                      </p>
                      <div className="mt-2 transition-all duration-300 transform hover:scale-[1.01]">
                        <div className={`border rounded-lg overflow-hidden transition-all duration-200 ${
                          editErrors.trainingText
                            ? 'border-red-300'
                            : 'border-gray-300 hover:border-blue-400'
                        }`}>
                          <ReactQuill
                            theme="snow"
                            value={trainingText}
                            onChange={setTrainingText}
                            modules={modules}
                            formats={formats}
                            placeholder="Введите текст для обучения бота..."
                            className="h-[400px] mb-12"
                          />
                        </div>
                        {editErrors.trainingText && (
                          <p className="mt-2 text-sm text-red-600 animate-bounce">{editErrors.trainingText}</p>
                        )}
                      </div>
                    </div>

                    {/* Кнопки */}
                    <div className="pt-5">
                      <div className="flex justify-end space-x-4">
                        <button
                          type="submit"
                          disabled={editLoading}
                          className={`inline-flex justify-center py-3 px-6 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-gradient-to-r from-blue-600 to-cyan-600 hover:from-blue-700 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:-translate-y-1 ${
                            editLoading ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                        >
                          {editLoading ? (
                            <>
                              <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                              Сохранение...
                            </>
                          ) : (
                            'Сохранить изменения'
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </TabsContent>

            <TabsContent value="widget" className="mt-6">
              <Tabs defaultValue="installation" className="w-full">
                <TabsList className="flex w-full bg-gray-100 p-1 rounded-lg gap-2 mb-4">
                  <TabsTrigger 
                    value="installation" 
                    className="flex-1 py-2 data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=active]:shadow-sm transition-all duration-200 rounded-lg font-medium"
                  >
                    <Layout className="w-4 h-4 mr-2" />
                    Установка
                  </TabsTrigger>
                  <TabsTrigger 
                    value="settings"
                    className="flex-1 py-2 data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=active]:shadow-sm transition-all duration-200 rounded-lg font-medium"
                  >
                    <Settings className="w-4 h-4 mr-2" />
                    Настройки
                  </TabsTrigger>
                </TabsList>

                <TabsContent value="installation">
                  <div className="bg-white rounded-xl shadow-lg p-6">
                    <h3 className="text-lg font-semibold mb-4">Установка виджета</h3>
                    <div className="space-y-4">
                      <div className="flex items-start gap-3">
                        <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-600 text-white flex items-center justify-center font-medium">
                          1
                        </div>
                        <div className="flex-1">
                          <p className="text-gray-700 mb-2">Скопируйте код виджета:</p>
                          <div className="relative bg-gray-50 rounded-lg border border-gray-200 overflow-hidden">
                            <pre className="p-4 text-sm font-mono overflow-x-auto">
                              <code>{generateWidgetCode()}</code>
                            </pre>
                            <button
                              onClick={() => {
                                navigator.clipboard.writeText(generateWidgetCode());
                                setCopied(true);
                                setTimeout(() => setCopied(false), 2000);
                              }}
                              className="absolute top-2 right-2 p-2 bg-white rounded-md shadow-sm hover:bg-gray-50 transition-colors"
                              title="Копировать код"
                            >
                              {copied ? (
                                <Check className="w-5 h-5 text-green-500" />
                              ) : (
                                <Copy className="w-5 h-5 text-gray-500" />
                              )}
                            </button>
                          </div>
                          {saveSuccess && (
                            <div className="mt-2 text-sm text-green-600 flex items-center gap-1">
                              <Check className="w-4 h-4" /> Настройки сохранены и будут применены при вставке кода
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex items-start gap-3">
                        <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-600 text-white flex items-center justify-center font-medium">
                          2
                        </div>
                        <div>
                          <p className="text-gray-700">
                            Вставьте скопированный код перед закрывающим тегом <code className="bg-gray-100 px-2 py-1 rounded text-sm">&lt;/body&gt;</code> на вашем сайте
                          </p>
                        </div>
                      </div>

                      <div className="flex items-start gap-3">
                        <div className="flex-shrink-0 w-6 h-6 rounded-full bg-blue-600 text-white flex items-center justify-center font-medium">
                          3
                        </div>
                        <div>
                          <p className="text-gray-700">
                            Сохраните изменения и обновите страницу вашего сайта. Виджет появится в выбранной позиции на странице.
                          </p>
                        </div>
                      </div>

                      <div className="mt-4 p-4 bg-blue-50 rounded-lg">
                        <div className="flex items-start gap-2">
                          <HelpCircle className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
                          <div>
                            <p className="text-sm text-blue-700">
                              Все настройки виджета (цвета, позиция, стиль кнопки и текст) встроены непосредственно в код.
                              Это означает, что виджет будет работать с этими настройками даже без подключения к серверу.
                              Каждый пользователь может настроить виджет индивидуально, не влияя на других пользователей.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="settings">
                  <div className="bg-white rounded-xl shadow-lg p-6">
                    <h3 className="text-lg font-semibold mb-4">Настройки виджета</h3>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                      <div className="lg:col-span-3 space-y-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Основной цвет
                            </label>
                            <div className="flex items-center gap-2">
                              <input
                                type="color"
                                value={widgetSettings.primaryColor}
                                onChange={(e) => handleWidgetSettingChange('primaryColor', e.target.value)}
                                className="h-10 w-10 rounded border border-gray-300 cursor-pointer"
                              />
                              <input
                                type="text"
                                value={widgetSettings.primaryColor}
                                onChange={(e) => handleWidgetSettingChange('primaryColor', e.target.value)}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                            <p className="mt-1 text-xs text-gray-500">
                              Цвет заголовка и основных элементов
                            </p>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Дополнительный цвет
                            </label>
                            <div className="flex items-center gap-2">
                              <input
                                type="color"
                                value={widgetSettings.secondaryColor}
                                onChange={(e) => handleWidgetSettingChange('secondaryColor', e.target.value)}
                                className="h-10 w-10 rounded border border-gray-300 cursor-pointer"
                              />
                              <input
                                type="text"
                                value={widgetSettings.secondaryColor}
                                onChange={(e) => handleWidgetSettingChange('secondaryColor', e.target.value)}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                            <p className="mt-1 text-xs text-gray-500">
                              Цвет акцентных элементов
                            </p>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Цвет кнопки
                            </label>
                            <div className="flex items-center gap-2">
                              <input
                                type="color"
                                value={widgetSettings.buttonColor}
                                onChange={(e) => handleWidgetSettingChange('buttonColor', e.target.value)}
                                className="h-10 w-10 rounded border border-gray-300 cursor-pointer"
                              />
                              <input
                                type="text"
                                value={widgetSettings.buttonColor}
                                onChange={(e) => handleWidgetSettingChange('buttonColor', e.target.value)}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                            <p className="mt-1 text-xs text-gray-500">
                              Цвет кнопки вызова виджета
                            </p>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Цвет иконки
                            </label>
                            <div className="flex items-center gap-2">
                              <input
                                type="color"
                                value={widgetSettings.iconColor}
                                onChange={(e) => handleWidgetSettingChange('iconColor', e.target.value)}
                                className="h-10 w-10 rounded border border-gray-300 cursor-pointer"
                              />
                              <input
                                type="text"
                                value={widgetSettings.iconColor}
                                onChange={(e) => handleWidgetSettingChange('iconColor', e.target.value)}
                                className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                            <p className="mt-1 text-xs text-gray-500">
                              Цвет иконки в кнопке вызова виджета
                            </p>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Текст заголовка
                            </label>
                            <input
                              type="text"
                              value={widgetSettings.headerText}
                              onChange={(e) => handleWidgetSettingChange('headerText', e.target.value)}
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              placeholder="AI Чат"
                            />
                            <p className="mt-1 text-xs text-gray-500">
                              Текст в заголовке виджета
                            </p>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Размер шрифта
                            </label>
                            <select
                              value={widgetSettings.fontSize}
                              onChange={(e) => handleWidgetSettingChange('fontSize', e.target.value)}
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            >
                              <option value="12px">Маленький (12px)</option>
                              <option value="14px">Средний (14px)</option>
                              <option value="16px">Большой (16px)</option>
                            </select>
                            <p className="mt-1 text-xs text-gray-500">
                              Размер шрифта в виджете
                            </p>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Позиция кнопки
                            </label>
                            <select
                              value={widgetSettings.buttonPosition}
                              onChange={(e) => handleWidgetSettingChange('buttonPosition', e.target.value as 'left' | 'right')}
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            >
                              <option value="left">Слева</option>
                              <option value="right">Справа</option>
                            </select>
                            <p className="mt-1 text-xs text-gray-500">
                              Расположение кнопки на странице
                            </p>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Стиль кнопки
                            </label>
                            <select
                              value={widgetSettings.buttonStyle}
                              onChange={(e) => handleWidgetSettingChange('buttonStyle', e.target.value as 'rounded' | 'square')}
                              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            >
                              <option value="rounded">Круглая</option>
                              <option value="square">Квадратная</option>
                            </select>
                            <p className="mt-1 text-xs text-gray-500">
                              Форма кнопки вызова виджета
                            </p>
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Приветственное сообщение
                          </label>
                          <textarea
                            value={widgetSettings.welcomeMessage}
                            onChange={(e) => handleWidgetSettingChange('welcomeMessage', e.target.value)}
                            rows={3}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Здравствуйте! Чем могу помочь?"
                          ></textarea>
                          <p className="mt-1 text-xs text-gray-500">
                            Сообщение, которое будет показано при открытии виджета
                          </p>
                        </div>

                        <div className="flex justify-end">
                          <button
                            onClick={handleSaveSettings}
                            disabled={saving}
                            className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                              saving ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                          >
                            {saving ? (
                              <>
                                <Loader2 className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" />
                                Сохранение...
                              </>
                            ) : saveSuccess ? (
                              <>
                                <Check className="-ml-1 mr-2 h-4 w-4 text-white" />
                                Сохранено!
                              </>
                            ) : (
                              <>
                                <Save className="-ml-1 mr-2 h-4 w-4 text-white" />
                                Сохранить настройки
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabsContent>
              </Tabs>
            </TabsContent>

            <TabsContent value="telegram" className="mt-6">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-4 sm:p-6">
                  <div className="mb-6 sm:mb-8">
                    <div className="flex items-center justify-between">
                      <h1 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-600 tracking-tight">
                        Интеграция с Telegram
                      </h1>
                      <button
                        onClick={() => setShowTelegramGuide(true)}
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
                      >
                        <HelpCircle className="w-4 h-4 mr-2" />
                        Подробная инструкция
                      </button>
                    </div>
                    <p className="mt-2 text-sm sm:text-base text-gray-600">
                      Подключите вашего бота к Telegram через защищенное WebSocket соединение
                    </p>
                  </div>

                  <div className="space-y-6 sm:space-y-8">
                    {/* Пошаговая инструкция */}
                    <div className="bg-gradient-to-r from-blue-50 to-cyan-50 rounded-xl p-4 sm:p-6">
                      <h3 className="text-base sm:text-lg font-semibold text-gray-900 mb-4">
                        Инструкция по подключению
                      </h3>
                      <div className="space-y-4 sm:space-y-6">
                        <div className="flex items-start gap-3 sm:gap-4">
                          <div className="flex-shrink-0 w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-blue-600 text-white flex items-center justify-center font-medium">
                            1
                          </div>
                          <div>
                            <h4 className="font-medium text-gray-900">Создайте бота</h4>
                            <p className="mt-1 text-sm text-gray-600">
                              Откройте @BotFather в Telegram и создайте нового бота
                            </p>
                            <a 
                              href="https://t.me/BotFather" 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="mt-2 inline-flex items-center text-sm text-blue-600 hover:text-blue-700"
                            >
                              <ArrowRight className="w-4 h-4 mr-1" />
                              Открыть @BotFather
                            </a>
                          </div>
                        </div>

                        <div className="flex items-start gap-3 sm:gap-4">
                          <div className="flex-shrink-0 w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-blue-600 text-white flex items-center justify-center font-medium">
                            2
                          </div>
                          <div>
                            <h4 className="font-medium text-gray-900">Получите токен</h4>
                            <p className="mt-1 text-sm text-gray-600">
                              Скопируйте токен, который пришлет @BotFather
                            </p>
                          </div>
                        </div>

                        <div className="flex items-start gap-3 sm:gap-4">
                          <div className="flex-shrink-0 w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-blue-600 text-white flex items-center justify-center font-medium">
                            3
                          </div>
                          <div>
                            <h4 className="font-medium text-gray-900">Подключите бота</h4>
                            <p className="mt-1 text-sm text-gray-600">
                              Введите токен в поле ниже и нажмите "Сохранить". Бот автоматически подключится через WebSocket.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Настройки бота */}
                    <div className="space-y-4 sm:space-y-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Токен Telegram бота
                        </label>
                        <div className="mt-1 relative rounded-lg shadow-sm">
                          <input
                            type="text"
                            value={telegramToken}
                            onChange={(e) => setTelegramToken(e.target.value)}
                            className="block w-full pr-10 border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 text-sm sm:text-base"
                            placeholder="Введите токен бота"
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            {telegramToken ? (
                              <Check className="h-5 w-5 text-green-500" />
                            ) : (
                              <Bell className="h-5 w-5 text-gray-400" />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <Shield className="h-5 w-5 text-yellow-600" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">
                              Безопасное соединение
                            </h3>
                            <p className="mt-2 text-sm text-yellow-700">
                              Бот использует защищенное WebSocket соединение для обмена сообщениями и хранения истории
                            </p>
                          </div>
                        </div>
                      </div>

                      <button
                        onClick={handleTelegramTokenSave}
                        disabled={saving || !telegramToken}
                        className="w-full flex items-center justify-center gap-2 px-4 sm:px-6 py-2.5 sm:py-3 border border-transparent text-sm sm:text-base font-medium rounded-lg text-white bg-gradient-to-r from-blue-600 to-cyan-600 hover:from-blue-700 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg hover:shadow-xl transition-all duration-200"
                      >
                        {saving ? (
                          <>
                            <Loader2 className="w-4 h-4 sm:w-5 sm:h-5 animate-spin" />
                            Подключение...
                          </>
                        ) : (
                          <>
                            <MessageSquare className="w-4 h-4 sm:w-5 sm:h-5" />
                            {telegramToken ? 'Переподключить бота' : 'Подключить бота'}
                          </>
                        )}
                      </button>
                    </div>

                    {/* Статус и возможности */}
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                      <div className="bg-green-50 rounded-lg p-4">
                        <h4 className="text-sm font-medium text-green-900 mb-2 flex items-center gap-2">
                          <Zap className="w-4 h-4" />
                          Мгновенный обмен
                        </h4>
                        <p className="text-sm text-green-800">
                          Быстрый обмен сообщениями через WebSocket
                        </p>
                      </div>

                      <div className="bg-blue-50 rounded-lg p-4">
                        <h4 className="text-sm font-medium text-blue-900 mb-2 flex items-center gap-2">
                          <MessageSquare className="w-4 h-4" />
                          История диалогов
                        </h4>
                        <p className="text-sm text-blue-800">
                          Автоматическое сохранение истории в базе данных
                        </p>
                      </div>

                      <div className="bg-purple-50 rounded-lg p-4">
                        <h4 className="text-sm font-medium text-purple-900 mb-2 flex items-center gap-2">
                          <Settings className="w-4 h-4" />
                          Автоподключение
                        </h4>
                        <p className="text-sm text-purple-800">
                          Автоматическое переподключение при разрыве соединения
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>

      {/* Фиксированный виджет в правом нижнем углу */}
      <div className="fixed z-50" style={{ 
        [widgetSettings.buttonPosition === 'right' ? 'right' : 'left']: '20px',
        bottom: '20px'
      }}>
        {/* Кнопка виджета */}
        <button 
          className={`flex items-center justify-center ${
            widgetSettings.buttonStyle === 'rounded' ? 'rounded-full' : 'rounded-md'
          } w-14 h-14 shadow-lg hover:shadow-xl transition-all duration-200`}
          style={{ backgroundColor: widgetSettings.buttonColor }}
          onClick={() => setIsWidgetOpen(!isWidgetOpen)}
        >
          {isWidgetOpen ? (
            <X style={{ color: widgetSettings.iconColor }} className="w-6 h-6" />
          ) : (
            <MessageSquare style={{ color: widgetSettings.iconColor }} className="w-6 h-6" />
          )}
        </button>

        {/* Окно виджета */}
        {isWidgetOpen && (
          <div 
            className={`absolute ${widgetSettings.buttonPosition === 'right' ? 'right-0' : 'left-0'} bottom-16 w-72 rounded-lg shadow-xl overflow-hidden z-20 transition-all duration-300`}
            style={{ fontSize: widgetSettings.fontSize }}
          >
            {/* Заголовок виджета */}
            <div 
              className="p-3 text-white font-medium flex items-center justify-between"
              style={{ backgroundColor: widgetSettings.primaryColor }}
            >
              <span>{widgetSettings.headerText}</span>
              <button 
                className="p-1 rounded-full hover:bg-white hover:bg-opacity-20 transition-colors"
                onClick={() => setIsWidgetOpen(false)}
              >
                <X className="w-4 h-4 text-white" />
              </button>
            </div>

            {/* Тело виджета */}
            <div className="bg-white h-80 flex flex-col">
              {/* Сообщения */}
              <div className="flex-1 p-3 overflow-y-auto">
                {/* Приветственное сообщение */}
                <div className="flex mb-3">
                  <div 
                    className="max-w-[80%] p-2 rounded-lg text-white"
                    style={{ backgroundColor: widgetSettings.secondaryColor }}
                  >
                    {widgetSettings.welcomeMessage}
                  </div>
                </div>

                {/* Пример сообщения пользователя */}
                <div className="flex justify-end mb-3">
                  <div 
                    className="max-w-[80%] p-2 rounded-lg text-white"
                    style={{ backgroundColor: widgetSettings.primaryColor }}
                  >
                    Здравствуйте! Как я могу оформить заказ?
                  </div>
                </div>

                {/* Пример ответа бота */}
                <div className="flex mb-3">
                  <div 
                    className="max-w-[80%] p-2 rounded-lg text-white"
                    style={{ backgroundColor: widgetSettings.secondaryColor }}
                  >
                    Для оформления заказа перейдите в раздел "Корзина" и нажмите кнопку "Оформить заказ". Затем заполните форму с вашими данными.
                  </div>
                </div>
              </div>

              {/* Поле ввода */}
              <div className="p-2 border-t">
                <div className="flex">
                  <input 
                    type="text" 
                    placeholder="Введите сообщение..." 
                    className="flex-1 px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-1"
                    style={{ fontSize: widgetSettings.fontSize }}
                  />
                  <button 
                    className="px-3 py-2 rounded-r-md text-white"
                    style={{ backgroundColor: widgetSettings.primaryColor }}
                  >
                    <Send className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}