import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useToast } from '@chakra-ui/react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { ArrowLeft, Loader2, UserPlus, Mail, Lock, AlertCircle, Shield } from 'lucide-react';
import { motion } from 'framer-motion';

const ADMIN_EMAIL = 'a777mmm@mail.ru';

interface UserData {
  email: string;
  createdAt: string;
  balance: number;
  isAdmin: boolean;
}

export default function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { signup } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    
    try {
      setLoading(true);
      const userCredential = await signup(email, password);
      const userData: UserData = {
        email,
        createdAt: new Date().toISOString(),
        balance: 5000,
        isAdmin: email === ADMIN_EMAIL
      };
      await setDoc(doc(db, 'users', userCredential.user.uid), userData);
      
      toast({
        title: 'Аккаунт создан.',
        status: 'success',
        duration: 3000,
      });
      navigate('/dashboard');
    } catch (error) {
      toast({
        title: 'Ошибка при создании аккаунта.',
        status: 'error',
        duration: 3000,
      });
    }
    setLoading(false);
  }

  const handleLoginClick = () => {
    navigate('/auth/login');
  };

  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <button
        onClick={handleHomeClick}
        className="absolute top-4 left-4 inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors"
      >
        <ArrowLeft className="w-5 h-5 mr-2" />
        На главную
      </button>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-center text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-cyan-600 tracking-tight mb-4">
            Регистрация
          </h2>
          <p className="text-center text-base text-gray-600">
            Или{' '}
            <button
              onClick={handleLoginClick}
              className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition-colors"
            >
              войдите в существующий аккаунт
            </button>
          </p>
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
        className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
      >
        <div className="bg-white py-8 px-4 shadow-xl sm:rounded-xl sm:px-10 space-y-8">
          <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <Shield className="h-5 w-5 text-blue-600" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-blue-800">
                  Безопасность
                </h3>
                <p className="mt-2 text-sm text-blue-700">
                  Используйте надежный пароль, содержащий не менее 8 символов, включая буквы, цифры и специальные символы.
                </p>
              </div>
            </div>
          </div>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1 relative rounded-lg shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full pl-10 px-3 py-2 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm transition-colors"
                  placeholder="example@email.com"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Пароль
              </label>
              <div className="mt-1 relative rounded-lg shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full pl-10 px-3 py-2 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm transition-colors"
                  placeholder="••••••••"
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Минимум 8 символов
              </p>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center items-center gap-2 px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-gradient-to-r from-blue-600 to-cyan-600 hover:from-blue-700 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed shadow-lg hover:shadow-xl"
              >
                {loading ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Регистрация...
                  </>
                ) : (
                  <>
                    <UserPlus className="w-5 h-5" />
                    Зарегистрироваться
                  </>
                )}
              </button>
            </div>
          </form>

          <div className="bg-gray-50 rounded-lg p-4">
            <h4 className="text-sm font-medium text-gray-900 mb-2">Преимущества регистрации:</h4>
            <ul className="space-y-2">
              <li className="flex items-start gap-2 text-sm text-gray-600">
                <div className="w-1 h-1 rounded-full bg-blue-400 mt-2" />
                <span>Доступ к панели управления ботом</span>
              </li>
              <li className="flex items-start gap-2 text-sm text-gray-600">
                <div className="w-1 h-1 rounded-full bg-blue-400 mt-2" />
                <span>Возможность создавать и настраивать ботов</span>
              </li>
              <li className="flex items-start gap-2 text-sm text-gray-600">
                <div className="w-1 h-1 rounded-full bg-blue-400 mt-2" />
                <span>Интеграция с Telegram и веб-сайтом</span>
              </li>
            </ul>
          </div>
        </div>
      </motion.div>
    </div>
  );
} 