import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { deleteUser, getAuth } from 'firebase/auth';
import { db } from '../config/firebase';
import { Button } from './ui/button';
import { Input } from './ui/input';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './ui/table';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { useNotification } from '../contexts/NotificationContext';
import { Search, UserPlus, Trash2, Wallet, Shield, X } from 'lucide-react';
import { motion } from 'framer-motion';

interface UserData {
  uid: string;
  email: string;
  createdAt: string;
  balance: number;
  isAdmin?: boolean;
}

export function AdminPanel() {
  const { isAdmin } = useAuth();
  const { showNotification } = useNotification();
  const [users, setUsers] = useState<UserData[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState<'createdAt' | 'balance'>('createdAt');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [editingUser, setEditingUser] = useState<UserData | null>(null);
  const [newBalance, setNewBalance] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isAdmin) {
      fetchUsers();
    }
  }, [isAdmin]);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData: UserData[] = [];

      for (const userDoc of usersSnapshot.docs) {
        const userData = userDoc.data() as Omit<UserData, 'uid'>;
        usersData.push({
          uid: userDoc.id,
          email: userData.email,
          createdAt: userData.createdAt,
          balance: userData.balance || 0,
          isAdmin: userData.isAdmin || false,
        });
      }

      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
      showNotification('error', 'Не удалось загрузить список пользователей');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (uid: string) => {
    if (!window.confirm('Вы уверены, что хотите удалить этого пользователя?')) {
      return;
    }

    try {
      const auth = getAuth();
      const userToDelete = auth.currentUser;
      if (userToDelete) {
        await deleteUser(userToDelete);
      }

      await deleteDoc(doc(db, 'users', uid));
      setUsers(users.filter(user => user.uid !== uid));
      showNotification('success', 'Пользователь успешно удален');
    } catch (error) {
      console.error('Error deleting user:', error);
      showNotification('error', 'Ошибка при удалении пользователя');
    }
  };

  const handleUpdateBalance = async () => {
    if (!editingUser) return;

    try {
      const userRef = doc(db, 'users', editingUser.uid);
      await updateDoc(userRef, {
        balance: Number(newBalance)
      });

      setUsers(users.map(user => 
        user.uid === editingUser.uid 
          ? { ...user, balance: Number(newBalance) }
          : user
      ));

      setEditingUser(null);
      setNewBalance('');
      showNotification('success', 'Баланс успешно обновлен');
    } catch (error) {
      console.error('Error updating balance:', error);
      showNotification('error', 'Ошибка при обновлении баланса');
    }
  };

  const handleToggleAdmin = async (user: UserData) => {
    if (!window.confirm(`Вы уверены, что хотите ${user.isAdmin ? 'убрать' : 'назначить'} права администратора для ${user.email}?`)) {
      return;
    }

    try {
      const userRef = doc(db, 'users', user.uid);
      const newIsAdmin = !user.isAdmin;
      
      await updateDoc(userRef, {
        isAdmin: newIsAdmin
      });

      setUsers(users.map(u => 
        u.uid === user.uid 
          ? { ...u, isAdmin: newIsAdmin }
          : u
      ));

      showNotification('success', `Права администратора успешно ${newIsAdmin ? 'назначены' : 'убраны'}`);
    } catch (error) {
      console.error('Error updating admin status:', error);
      showNotification('error', 'Ошибка при обновлении прав администратора');
    }
  };

  const filteredUsers = users
    .filter(user => 
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      const compareValue = sortDirection === 'asc' ? 1 : -1;
      if (sortField === 'balance') {
        return (a.balance - b.balance) * compareValue;
      }
      return (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) * compareValue;
    });

  if (!isAdmin) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-center p-6 bg-red-50 rounded-lg">
          <Shield className="w-12 h-12 text-red-500 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-red-700 mb-2">Доступ запрещен</h2>
          <p className="text-red-600">У вас нет прав для просмотра этой страницы.</p>
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-4 sm:p-6 space-y-6"
    >
      <div className="flex flex-col gap-4 mb-4 sm:mb-6">
        <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-4">
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
            Панель администратора
          </h1>
          <div className="relative w-full sm:w-80">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
            <Input
              type="text"
              placeholder="Поиск по email..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="pl-10 w-full"
            />
          </div>
        </div>
        
        <div className="flex flex-wrap gap-2">
          <Button
            variant={sortField === 'createdAt' ? "default" : "outline"}
            onClick={() => {
              setSortField('createdAt');
              setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
            }}
            className="flex-1 sm:flex-none whitespace-nowrap text-sm"
          >
            По дате {sortField === 'createdAt' && (sortDirection === 'asc' ? '↑' : '↓')}
          </Button>
          <Button
            variant={sortField === 'balance' ? "default" : "outline"}
            onClick={() => {
              setSortField('balance');
              setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
            }}
            className="flex-1 sm:flex-none whitespace-nowrap text-sm"
          >
            По балансу {sortField === 'balance' && (sortDirection === 'asc' ? '↑' : '↓')}
          </Button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto -mx-4 sm:mx-0">
          {isLoading ? (
            <div className="flex items-center justify-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : filteredUsers.length === 0 ? (
            <div className="text-center p-8">
              <UserPlus className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">Нет пользователей</h3>
              <p className="mt-1 text-sm text-gray-500">
                {searchQuery ? 'Попробуйте изменить параметры поиска' : 'Начните с добавления нового пользователя'}
              </p>
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Email</TableHead>
                  <TableHead className="hidden lg:table-cell">ID</TableHead>
                  <TableHead className="hidden md:table-cell">Дата</TableHead>
                  <TableHead>Баланс</TableHead>
                  <TableHead className="hidden sm:table-cell">Статус</TableHead>
                  <TableHead className="text-right">Действия</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredUsers.map((user) => (
                  <TableRow key={user.uid} className="group">
                    <TableCell className="max-w-[120px] sm:max-w-none">
                      <div className="font-medium truncate">{user.email}</div>
                      <div className="sm:hidden text-xs text-gray-500 mt-1">
                        {new Date(user.createdAt).toLocaleDateString()}
                      </div>
                      <div className="sm:hidden text-xs text-gray-500 mt-1">
                        {user.isAdmin ? 'Администратор' : 'Пользователь'}
                      </div>
                    </TableCell>
                    <TableCell className="hidden lg:table-cell">
                      <span className="text-xs text-gray-500 truncate block max-w-[100px]" title={user.uid}>
                        {user.uid}
                      </span>
                    </TableCell>
                    <TableCell className="hidden md:table-cell whitespace-nowrap">
                      {new Date(user.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button
                            variant="ghost"
                            className="px-2 py-1 h-auto text-sm hover:bg-blue-50 group-hover:bg-blue-50"
                            onClick={() => {
                              setEditingUser(user);
                              setNewBalance(user.balance.toString());
                            }}
                          >
                            <Wallet className="h-4 w-4 mr-1.5 text-blue-600" />
                            <span className="tabular-nums">{user.balance.toLocaleString()}</span>
                            <span className="text-xs ml-1">₸</span>
                          </Button>
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>Изменить баланс</DialogTitle>
                          </DialogHeader>
                          <div className="space-y-4 py-4">
                            <div className="space-y-2">
                              <label className="text-sm font-medium text-gray-700">
                                Новый баланс
                              </label>
                              <Input
                                type="number"
                                value={newBalance}
                                onChange={(e) => setNewBalance(e.target.value)}
                                placeholder="Введите сумму..."
                              />
                            </div>
                            <div className="flex justify-end gap-3">
                              <Button
                                variant="outline"
                                onClick={() => {
                                  setEditingUser(null);
                                  setNewBalance('');
                                }}
                              >
                                Отмена
                              </Button>
                              <Button onClick={handleUpdateBalance}>
                                Сохранить
                              </Button>
                            </div>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </TableCell>
                    <TableCell className="hidden sm:table-cell">
                      <Button
                        variant={user.isAdmin ? "default" : "outline"}
                        size="sm"
                        onClick={() => handleToggleAdmin(user)}
                        className="h-8 px-2 text-xs"
                      >
                        <Shield className="h-3.5 w-3.5 mr-1" />
                        {user.isAdmin ? 'Админ' : 'Пользователь'}
                      </Button>
                    </TableCell>
                    <TableCell className="text-right">
                      <div className="flex justify-end gap-2">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleToggleAdmin(user)}
                          className="h-8 w-8 sm:hidden"
                        >
                          <Shield className="h-4 w-4 text-blue-600" />
                        </Button>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDeleteUser(user.uid)}
                          className="h-8 w-8 text-red-600 hover:text-red-700 hover:bg-red-50"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </motion.div>
  );
} 