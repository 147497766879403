import { getAuth } from 'firebase/auth';

export class TelegramBot {
  private ws: WebSocket | null = null;
  private token: string;
  private openaiToken: string | null = null;
  private messageQueue: { chatId: number; text: string }[] = [];
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 5;
  private reconnectTimeout = 1000;
  private messageHandlers: ((chatId: number, text: string, from: any) => void)[] = [];

  constructor(token: string) {
    this.token = token;
  }

  onMessage(handler: (chatId: number, text: string, from: any) => void) {
    this.messageHandlers.push(handler);
  }

  async connect() {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('Пользователь не авторизован');
      }

      const userId = user.uid;
      const wsUrl = `wss://telegram-bot-ws-296316387892.us-central1.run.app/${userId}`;
      this.ws = new WebSocket(wsUrl);

      this.ws.onopen = () => {
        console.log('WebSocket соединение установлено');
        // Отправляем токен Telegram бота для инициализации
        this.ws?.send(JSON.stringify({
          type: 'init',
          token: this.token,
          botId: localStorage.getItem('currentBotId')
        }));
      };

      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Получено сообщение от сервера:', data);

        if (data.type === 'init_success') {
          console.log('Бот успешно инициализирован');
          // Сохраняем токен OpenAI
          this.openaiToken = data.openaiToken;
          // Отправляем сообщения из очереди
          this.processMessageQueue();
        } else if (data.type === 'telegram_message') {
          // Обрабатываем входящее сообщение от Telegram
          console.log('Получено сообщение от Telegram:', data);
          this.messageHandlers.forEach(handler => {
            handler(data.chatId, data.text, data.from);
          });
        } else if (data.type === 'error') {
          console.error('Ошибка от сервера:', data.message);
        }
      };

      this.ws.onclose = (event) => {
        console.log('WebSocket соединение закрыто:', event.code, event.reason);
        this.handleReconnect();
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket ошибка:', error);
      };

    } catch (error) {
      console.error('Ошибка при установке WebSocket соединения:', error);
      this.handleReconnect();
    }
  }

  private handleReconnect() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      console.log(`Попытка переподключения ${this.reconnectAttempts}/${this.maxReconnectAttempts}`);
      setTimeout(() => {
        this.connect();
      }, this.reconnectTimeout * this.reconnectAttempts);
    } else {
      console.error('Достигнуто максимальное количество попыток переподключения');
    }
  }

  private async processMessageQueue() {
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();
      if (message) {
        await this.handleMessage(message.chatId, message.text);
      }
    }
  }

  async handleMessage(chatId: number, text: string) {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      console.log('WebSocket не подключен, добавляем сообщение в очередь');
      this.messageQueue.push({ chatId, text });
      return;
    }

    if (!this.openaiToken) {
      console.error('Токен OpenAI не инициализирован');
      return;
    }

    // Получаем botId из URL
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.error('Пользователь не авторизован');
      return;
    }

    // Получаем botId из localStorage
    const botId = localStorage.getItem('currentBotId');
    if (!botId) {
      console.error('botId не найден');
      return;
    }

    console.log('Отправка сообщения с botId:', botId);

    this.ws.send(JSON.stringify({
      type: 'message',
      chatId,
      text,
      botId,
      openaiToken: this.openaiToken
    }));
  }

  private async sendTelegramMessage(chatId: number, text: string) {
    try {
      const response = await fetch(`https://api.telegram.org/bot${this.token}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: text,
        }),
      });

      if (!response.ok) {
        throw new Error(`Telegram API error: ${response.status}`);
      }

      console.log('Сообщение успешно отправлено в Telegram');
    } catch (error) {
      console.error('Ошибка при отправке сообщения в Telegram:', error);
    }
  }

  disconnect() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }
} 